import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tags-accreditations',
  templateUrl: './accreditations.component.html',
  styleUrls: ['./accreditations.component.scss'],
})
export class AccreditationsComponent implements OnInit {
  images = [
    {
      img: '../../../assets/hada.png',
      name: 'HADA',
    },
    {
      img: '../../../assets/women-owned.png',
      name: 'Women Owned',
    },
    {
      img: '../../../assets/nada.png',
      name: 'NADA',
    },
    {
      img: '../../../assets/state-of-hawaii.png',
      name: 'State of Hawaii',
    },
    {
      img: '../../../assets/bbb.png',
      name: 'BBB',
    },
  ];
  slides: any = [[]];

  constructor() {}

  ngOnInit() {
    this.slides = this.chunk(this.images, 3);
  }

  chunk(arr: any, chunkSize: any) {
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
}
