import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route, UrlTree } from '@angular/router';

import { AuthenticationService } from '../_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authenticationService.loggedIn) {
      // authorised so return true
      return true;
    }

    // not logged in so redirect to home page with the return url
    this.router.navigate(['/home']);
    return false;
  }

  checkLogin(url: string): boolean {
    if (this.authenticationService.loggedIn) {
      return true;
    }

    // Store the attempted URL for redirecting
    // this.authenticationService.redirectUrl = url;

    // Redirect to the login page
    this.router.navigate(['/login']);
    return false;
  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;

    return this.checkLogin(url);
  }
}
