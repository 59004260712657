// tslint:disable: indent
// tslint:disable: component-selector
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';
import { AuthenticationService } from './_services/authentication.service';
import { DealerProfile } from './_helpers/dealer-profile';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private authenticationService = inject(AuthenticationService);
  private platformID = inject(PLATFORM_ID);
  currentDealer: DealerProfile;
  sub: Subscription;

  constructor() {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      this.sub = this.authenticationService.currentDealer.subscribe(
        (x) => (this.currentDealer = x)
      );
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformID)) {
      this.sub?.unsubscribe();
    }
  }
}
