import { DOCUMENT } from '@angular/common';
import { ComponentFactoryResolver, Directive, ElementRef, HostListener, Inject, Input, Renderer2, SimpleChanges, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ControlMessagesComponent } from '../control-messages/control-messages.component';

@Directive({
  selector: '[tagsFormControlStatus]'
})
export class FormControlStatusDirective {

  @Input() tagsFormControlStatus: NgControl;
  @HostListener('focus', ['$event.target']) onFocus(e) {
    this.checkControlStatus()
  }
  @HostListener('blur', ['$event.target']) onBlur(e) {
    this.checkControlStatus()
  }
  @HostListener('input', ['$event.target']) onInput(e) {
    this.checkControlStatus()
  }

  controlMsgComp: any;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngOnInit() {
    //Append Control Message component once
    const componentFactory = this.componentFactoryResolver
      .resolveComponentFactory(ControlMessagesComponent);
    
    this.controlMsgComp = this.viewContainerRef.createComponent(componentFactory);
    this.controlMsgComp.instance.control = this.tagsFormControlStatus;
    this.renderer.appendChild(this.elementRef.nativeElement.parentElement, this.controlMsgComp.location.nativeElement);

  }

  checkControlStatus() {
    // Update control message component property to show error if any
    if(this.controlMsgComp) {
      this.controlMsgComp.instance.control = this.tagsFormControlStatus;
    }
    let nodeToDelete: string[] = ["fa-exclamation-circle", "fa-check"];
    this.elementRef.nativeElement.parentElement.childNodes.forEach(node => {
      if (nodeToDelete.indexOf(node.id) !== -1) {
        this.renderer.removeChild(this.elementRef.nativeElement.parentElement, node)
      }
    })
    if (this.tagsFormControlStatus.invalid && (this.tagsFormControlStatus.dirty || this.tagsFormControlStatus.touched)) {
      //console.log("fa-exclamation-circle", this.elementRef.nativeElement)
      const child = this.document.createElement('i');
      child.classList.add("fas", "fa-exclamation-circle", "icon")
      child.id = "fa-exclamation-circle";

      this.renderer.appendChild(this.elementRef.nativeElement.parentElement, child)

      setTimeout(() => {
        let errMsgElement: HTMLElement[] = this.elementRef.nativeElement.parentElement.getElementsByTagName('control-messages')
        if(errMsgElement?.length) {
          //Check error element has child elemts
          if(errMsgElement[0].children?.length) {
            //compare error element width with input width
            this.controlMsgComp.instance.showEllipsisEffect = (errMsgElement[0].children[0].clientWidth == this.elementRef.nativeElement.parentElement.clientWidth);
          }
        }
      });

      //this.controlMsgComp.instance.showEllipsisEffect = true;
    }
    if (this.tagsFormControlStatus.valid && (this.tagsFormControlStatus.dirty || this.tagsFormControlStatus.touched)) {
      //console.log("fa-check")
      const child = this.document.createElement('i');
      child.classList.add("fas", "fa-check", "icon")
      child.id = "fa-check";

      this.renderer.appendChild(this.elementRef.nativeElement.parentElement, child)

    }
    
  }
}
