import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { DealerProfile } from '../_helpers/dealer-profile';
import { LookuplistsService } from './lookuplists.service';
import {
  MdbNotificationRef,
  MdbNotificationService,
} from 'mdb-angular-ui-kit/notification';
import { NotificationComponent } from '../_shared/notification/notification.component';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

export function getHeader() {
  return sessionStorage.getItem('authHeader');
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentDealerSubject: BehaviorSubject<DealerProfile>;
  private loggedInSubject: BehaviorSubject<boolean>;
  public isLoggedIn$: Observable<boolean>;
  public currentDealer: Observable<DealerProfile>;
  private _loggedIn = false;
  private lookuplists = inject(LookuplistsService);
  private http = inject(HttpClient);
  private toastService = inject(MdbNotificationService);
  private notificationRef: MdbNotificationRef<NotificationComponent> | null =
    null;

  constructor(@Inject(PLATFORM_ID) private platformID: any) {
    if (isPlatformBrowser(platformID)) {
      this.currentDealerSubject = new BehaviorSubject<DealerProfile>(
        JSON.parse(
          sessionStorage.getItem('dealerProfile') === 'undefined'
            ? null
            : sessionStorage.getItem('dealerProfile')
        )
      );
      this.loggedInSubject = new BehaviorSubject<boolean>(
        sessionStorage.getItem('dealerProfile') !== null
      );
    } else if (isPlatformServer(platformID)) {
      this.currentDealerSubject = new BehaviorSubject<DealerProfile>(null);
      this.loggedInSubject = new BehaviorSubject<boolean>(false);
    }

    this.currentDealer = this.currentDealerSubject.asObservable();
    this.isLoggedIn$ = this.loggedInSubject.asObservable();
  }

  public get currentDealerValue(): any {
    return this.currentDealerSubject.value;
  }

  public get loggedIn(): boolean {
    return this.loggedInSubject.value;
  }

  // get dealerCode() {
  //   return this.loginFields.dealerCode;
  // }

  register() {}

  login(
    dealerCode: string,
    username: string,
    password: string,
    recaptcha: string
  ) {
    const loginBody = {
      dealer: dealerCode,
      userName: username,
      password: password,
      recaptcha: recaptcha,
    };

    return this.http.post<DealerProfile>(
      environment.apiUrl + '/session',
      loginBody,
      {
        withCredentials: true,
        responseType: 'json',
      }
    );
  }

  logout() {
    // remove user from local storage and set current user to null
    this._loggedIn = false;
    sessionStorage.removeItem('dealerProfile');
    sessionStorage.removeItem('lookupLists');
    sessionStorage.clear();
    this.currentDealerSubject.next(null);
    this.loggedInSubject.next(false);
  }

  async postLogin(dealer: DealerProfile) {
    // Update the stored dealer profile
    this.currentDealerSubject.next(dealer);
    this.loggedInSubject.next(true);

    this.lookuplists.fetchLists().subscribe({
      next: (data) => {
        // Store the vehicle form lookup list results
        sessionStorage.setItem('lookupLists', JSON.stringify(data));
      },
      error: (err) => {
        console.error('Lookup List Fetch Error: ', err);
        this.notificationRef = this.toastService.open(NotificationComponent, {
          autohide: true,
          animation: true,
          delay: 3500,
          data: {
            header: 'Lookup List Fetch Error',
            text: 'Lookup lists failed to fetch.',
            severity: 'error',
          },
          stacking: true,
        });
      },
      complete: () => {
        console.log('Lookup Lists Retrieved');
      },
    });
  }

  failed() {
    this._loggedIn = false;
  }

  // success() {
  //   this.loggedIn = true;
  // }
}
