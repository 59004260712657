import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ContactFormComponent } from '../contact-form/contact-form.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'tags-home',
  templateUrl: 'home.component.html',
})
export class HomeComponent implements OnInit {
  longitude = -157.950638;
  latitude = 21.38314;
  contactModal: MdbModalRef<ContactFormComponent> | null = null;

  public map: any = { lat: this.latitude, lng: this.longitude };

  markers = [{ latitude: this.latitude, longitude: this.longitude }];

  constructor(
    private modalService: MdbModalService
  ) {
  }

  ngOnInit() {
  }

  openContactModal() {
    this.contactModal = this.modalService.open(ContactFormComponent, {
      ignoreBackdropClick: true,
      modalClass: 'modal-lg',
    });
  }
}
