import { Component, OnInit, ViewChild } from '@angular/core';
import { MdbTableDirective } from 'mdb-angular-ui-kit/table';

interface TermsAndCodes {
  code: string;
  description: string;
}

@Component({
  selector: 'tags-tags-tips',
  templateUrl: './tags-tips.component.html',
  styleUrls: ['./tags-tips.component.scss'],
})
export class TagsTipsComponent implements OnInit {
  @ViewChild('codeTable') table!: MdbTableDirective<TermsAndCodes>;

  headers: string[] = ['Code', 'Description'];

  codeList: TermsAndCodes[] = [
    {
      code: 'AIRBILL',
      description:
        'A shipment tracking number used by carrier services like FedEx and UPS.',
    },
    {
      code: 'BOL',
      description:
        'Bill of Lading. This is a document supplied by the shipping company.',
    },
    {
      code: 'BOS',
      description:
        'Bill of Sale. Similar to a vehicle-sale receipt, a BOS is a document that transfers ownership of a vehicle from one entity to another.',
    },
    {
      code: 'COI',
      description:
        'Certificate of Insurance. This is provided by the insurance company either as an actual card or as an insurance-binder agreement. COIs prove that vehicles meet the minimum insurance-liability requirements.',
    },
    {
      code: 'DMV',
      description: 'Department of Motor Vehicles.',
    },
    {
      code: 'DUP TITLE',
      description:
        'Duplicate Title. A second title which can be retrieved and used when the current or original title is lost, destroyed or mutilated.',
    },
    {
      code: 'DUP REG',
      description:
        'Duplicate Registration. A second registration which can be requested and used when the current or original registration is lost, destroyed or mutilated.',
    },
    {
      code: 'EDR',
      description:
        'Earliest Day to Renew. The earliest day to renew in the State of Hawaii is 45 days day out from date of expiry.',
    },
    {
      code: 'ELT',
      description:
        'Electronic Lien and Title. Also known as a digital or non-paper lien and title.',
    },
    {
      code: 'FEIN',
      description:
        'Nine-digit number provided to a company by the IRS. Original issuance is given on IRS form 147C.',
    },
    {
      code: 'GVWR',
      description:
        'Gross Vehicle Weight Rating. The maximum total weight of a vehicle.',
    },
    {
      code: 'HVUC',
      description:
        'Heavy-use vehicle tax document. Required for vehicles in the State of Hawaii with a gross vehicle weight of 8,000 pounds or more.',
    },
    {
      code: 'Safety Inspection',
      description:
        'All vehicles must have a current safety inspection from a state-certified location. During such an inspection, a set list of items on the vehicle are confirmed to be in safe operating condition.',
    },
    {
      code: 'Lessee',
      description:
        'The person or business, also known as the registrant, who drives and makes payments on the vehicle.',
    },
    {
      code: 'Lessor',
      description:
        'The owner of the vehicle, oftentimes the leasing company that leases out the vehicle and collects monthly lease payments from the lessee.',
    },
    {
      code: 'Lien',
      description:
        'When a vehicle is owned by a person or business but is financed through a financial institution, such as a bank or a credit union. The debt, in the form of a lien, is then carried by the financial institution, which serves as the lienholder.',
    },
    {
      code: 'Lien Release',
      description:
        'When the debt of a vehicle is fully repaid, and the lien on the title is removed (i.e. “released”).',
    },
    {
      code: 'Lienholder',
      description:
        'The financial institution, such as a bank or credit union, that loans funds to the vehicle buyer for the purchase.',
    },
    {
      code: 'MSO',
      description: `Manufacturer's Statement of Origin. This serves as a title before a vehicle is assigned to a certain state.`,
    },
    {
      code: 'Plate',
      description:
        'A license plate on a vehicle, also known as a vehicle registration plate.',
    },
    {
      code: 'POA',
      description: `Power of Attorney. Written authorization to represent or act on another's behalf in private, business or other legal affairs. As a title-and-registration service provider, TAGS uses POA to sign title applications and other licensing documents, as well as to obtain the title and/or registration on behalf of the vehicle's owner.`,
    },
    {
      code: 'Repo',
      description:
        'Repossession. When a lienholder repossesses a vehicle after the owner defaults on loan payments.',
    },
    {
      code: 'Title',
      description:
        'A legal document that establishes the person or business who legally owns a vehicle.',
    },
    {
      code: 'VIN',
      description:
        'Vehicle Identification Number that is unique to each respective vehicle. Consists of a 17-digit sequence of numbers and letters.',
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.table.search(searchTerm);
  }
}
