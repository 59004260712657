<!-- <header [ngClass]="{ 'logged-in-padding': loggedInPadding }">
</header> -->

<mdb-sidenav-layout>
  <mdb-sidenav
    #tagsSidenav="mdbSidenav"
    id="tags-sidenav"
    [mode]="mode"
    [hidden]="hidden"
    [accordion]="true"
    [backdrop]="true"
  >
    <a class="d-flex justify-content-center py-4">
      <img
        id="tags-logo"
        src="../../../assets/tags-logo-sm.png"
        alt="TAGS Logo"
        draggable="false"
      />
    </a>
    <div class="text-center">
      <h5 class="tags-secondary-color">
        <strong
          >Dealer:
          <span *ngIf="dealerProfile" class="px-1 text-black-50">{{
            dealerCode
          }}</span>
        </strong>
      </h5>
      <hr class="m-0" />
    </div>
    <ul class="sidenav-menu">
      <mdb-sidenav-item>
        <a
          class="sidenav-link"
          routerLink="/account"
          routerLinkActive="active"
          tabindex="0"
          mdbRipple
          (click)="onNavigate()"
        >
          <i class="fas fa-columns fa-fw me-3"></i><span>Dashboard</span>
        </a>
      </mdb-sidenav-item>
      <mdb-sidenav-item>
        <a
          class="sidenav-link"
          tabindex="0"
          mdbRipple
          role="button"
          aria-controls="vehicleCategories"
          [aria-expanded]="collapseVehCats"
          ><i class="fas fa-car-side fa-fw me-3"></i><span>Vehicles</span></a
        >
        <ul
          class="sidenav-collapse"
          mdbCollapse
          id="vehicles"
          #vehicleCategories="mdbCollapse"
          [collapsed]="!vehiclesLinks.isActive"
          #vehiclesLinks="routerLinkActive"
          routerLinkActive
        >
          <li class="sidenav-item">
            <a
              class="sidenav-link"
              routerLink="/vehicle"
              routerLinkActive="active"
              tabindex="0"
              mdbRipple
              (click)="onNavigate()"
              >Vehicle Form</a
            >
          </li>
          <li class="sidenav-item">
            <a
              class="sidenav-link"
              routerLink="/inquiry"
              routerLinkActive="active"
              tabindex="0"
              mdbRipple
              (click)="onNavigate()"
              >Vehicle Inquiry</a
            >
          </li>
          <li class="sidenav-item">
            <a
              class="sidenav-link"
              routerLink="/inventory"
              routerLinkActive="active"
              tabindex="0"
              mdbRipple
              (click)="onNavigate()"
              >Plate Inventory</a
            >
          </li>
          <li class="sidenav-item" *ngIf="showBatchRenewals()">
            <a
              class="sidenav-link"
              routerLink="/batch-renewals"
              routerLinkActive="active"
              tabindex="0"
              mdbRipple
              (click)="onNavigate()"
              >Batch Renewals</a
            >
          </li>
        </ul>
      </mdb-sidenav-item>
      <mdb-sidenav-item>
        <a
          class="sidenav-link"
          mdbRipple
          tabindex="0"
          role="button"
          [aria-expanded]="collapseCardCats"
          aria-controls="cardsCategories"
          ><i class="fas fa-address-card fa-fw me-3"></i><span>Cards</span></a
        >
        <ul
          class="sidenav-collapse"
          mdbCollapse
          id="cardsCategories"
          #cardsCategories="mdbCollapse"
          [collapsed]="!cardsLinks.isActive"
          #cardsLinks="routerLinkActive"
          routerLinkActive
        >
          <li class="sidenav-item">
            <a
              class="sidenav-link"
              routerLink="/application"
              routerLinkActive="active"
              tabindex="0"
              mdbRipple
              (click)="onNavigate()"
              >Application Card</a
            >
          </li>
          <li class="sidenav-item">
            <a
              class="sidenav-link"
              routerLink="/registration"
              routerLinkActive="active"
              tabindex="0"
              mdbRipple
              (click)="onNavigate()"
              >Registration Card</a
            >
          </li>
        </ul>
      </mdb-sidenav-item>
      <mdb-sidenav-item>
        <a
          class="sidenav-link"
          tabindex="0"
          mdbRipple
          role="button"
          [aria-expanded]="collapseReportCats"
          aria-controls="reportsCategories"
          ><i class="far fa-newspaper fa-fw me-3"></i><span>Reports</span></a
        >
        <ul
          class="sidenav-collapse"
          mdbCollapse
          id="reportsCategories"
          #reportsCategories="mdbCollapse"
          [collapsed]="!reportsLinks.isActive"
          #reportsLinks="routerLinkActive"
          routerLinkActive
        >
          <li class="sidenav-item">
            <a
              class="sidenav-link"
              routerLink="/cashier"
              routerLinkActive="active"
              tabindex="0"
              mdbRipple
              (click)="onNavigate()"
              >Cashier Report</a
            >
          </li>
          <li class="sidenav-item">
            <a
              class="sidenav-link"
              routerLink="/g27-report"
              routerLinkActive="active"
              tabindex="0"
              mdbRipple
              (click)="onNavigate()"
              >G-27 Report</a
            >
          </li>
          <li class="sidenav-item">
            <a
              class="sidenav-link"
              routerLink="/pod-report"
              routerLinkActive="active"
              tabindex="0"
              mdbRipple
              (click)="onNavigate()"
              >POD Report</a
            >
          </li>
          <li class="sidenav-item">
            <a
              class="sidenav-link"
              routerLink="/refund"
              routerLinkActive="active"
              tabindex="0"
              mdbRipple
              (click)="onNavigate()"
              >Refund Report</a
            >
          </li>
          <li class="sidenav-item">
            <a
              class="sidenav-link"
              routerLink="/unrenewed-vehicle-report"
              routerLinkActive="active"
              tabindex="0"
              mdbRipple
              (click)="onNavigate()"
              >Unrenewed Vehicle Report</a
            >
          </li>
        </ul>
      </mdb-sidenav-item>
    </ul>
  </mdb-sidenav>
  <mdb-sidenav-content #sidenavContent>
    <header>
      <nav
        *ngIf="dealerLoggedIn$ | async; else notLoggedIn"
        id="logged-in-navbar"
        class="navbar navbar-expand-lg navbar-light bg-white"
      >
        <div class="container-fluid">
          <button
            class="btn shadow-0 p-0 me-3 d-block d-xxl-none"
            aria-label="toggle"
            aria-controls="#tagsSidenav-1"
            aria-haspopup="true"
            (click)="toggleSidenav()"
          >
            <i class="fas fa-bars fa-lg"></i>
          </button>

          <ul class="navbar-nav ms-auto d-flex flex-row">
            <li class="nav-item">
              <a
                class="nav-link"
                role="button"
                mdbRipple
                (click)="openContactModal()"
              >
                <i class="fas fa-envelope fa-lg tags-secondary-color me-2"></i>
                <span class="tags-secondary-color">Contact Us</span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                role="button"
                (click)="logout()"
                mdbRipple
              >
                <i
                  class="fas fa-sign-out-alt fa-lg tags-secondary-color me-2"
                ></i>
                <span class="tags-secondary-color">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <ng-template #notLoggedIn>
        <nav
          id="public-navbar"
          class="navbar navbar-expand-lg navbar-light bg-light fixed-top scrolling-navbar z-depth-1"
        >
          <div class="container-fluid">
            <a class="navbar-brand p-0" routerLink="/home">
              <img
                src="../../../assets/tags-logo.png"
                height="50"
                alt="TAGS Hawaii, Inc."
              />
            </a>

            <button
              class="navbar-toggler"
              type="button"
              (click)="publicNavbar.toggle()"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i class="fas fa-bars tags-secondary-color"></i>
            </button>

            <div
              class="collapse navbar-collapse justify-content-center"
              id="navbarSupportedContent"
              mdbCollapse
              #publicNavbar="mdbCollapse"
            >
              <ul class="navbar-nav d-flex flex-row me-auto mb-2 mb-lg-0">
                <li class="nav-item me-3 me-lg-0">
                  <a
                    class="nav-link"
                    href="https://www.facebook.com/Tags-Hawaii-Inc-529750720427149/"
                    target="_blank"
                    (click)="onNavbarClick()"
                  >
                    <i class="fab fa-facebook-f fa-lg tags-secondary-color"></i>
                  </a>
                </li>
                <li class="nav-item me-3 me-lg-0">
                  <a
                    class="nav-link"
                    href="https://www.linkedin.com/company/tags-hawaii-inc/"
                    target="_blank"
                    (click)="onNavbarClick()"
                  >
                    <i
                      class="fab fa-linkedin-in fa-lg tags-secondary-color"
                    ></i>
                  </a>
                </li>
                <li class="nav-item me-3 me-lg-0">
                  <a
                    class="nav-link"
                    href="https://www.bbb.org/us/hi/aiea/profile/auto-registration/tags-hawaii-inc-1296-1367"
                    target="_blank"
                    (click)="onNavbarClick()"
                  >
                    <i
                      class="fas fa-globe-americas fa-lg tags-secondary-color"
                    ></i>
                  </a>
                </li>
              </ul>

              <ul class="navbar-nav justify-content-center mb-2 mb-lg-0">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/home"
                    (click)="onNavbarClick()"
                    mdbRipple
                  >
                    <span class="tags-secondary-color">Home</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    mdbRipple
                    routerLink="/our-team"
                    routerLinkActive="active"
                    (click)="onNavbarClick()"
                  >
                    <span class="tags-secondary-color">Our Team</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    mdbRipple
                    routerLink="/meet-the-ceo"
                    routerLinkActive="active"
                    (click)="onNavbarClick()"
                  >
                    <span class="tags-secondary-color">Meet the CEO</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/faq"
                    routerLinkActive="active"
                    (click)="onNavbarClick()"
                    mdbRipple
                  >
                    <span class="tags-secondary-color">FAQ</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    mdbRipple
                    routerLink="/our-tips"
                    routerLinkActive="active"
                    (click)="onNavbarClick()"
                  >
                    <span class="tags-secondary-color">Our Tips</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    mdbRipple
                    routerLink="/accreditations"
                    routerLinkActive="active"
                    (click)="onNavbarClick()"
                  >
                    <span class="tags-secondary-color">Accreditations</span>
                  </a>
                </li>
              </ul>
              <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    role="button"
                    mdbRipple
                    (click)="openContactModal()"
                  >
                    <i
                      class="fas fa-envelope fa-lg tags-secondary-color p-1"
                    ></i>
                    <span class="tags-secondary-color">Contact Us</span>
                  </a>
                </li>
                <li class="nav-item" *ngIf="!hideLogin">
                  <a
                    class="nav-link"
                    role="button"
                    mdbRipple
                    (click)="openLoginModal()"
                  >
                    <i class="fas fa-user fa-lg tags-secondary-color p-1"></i>
                    <span class="tags-secondary-color">Login</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </ng-template>
    </header>
    <main
      [ngClass]="{
        'logged-in-padding': loggedInPadding,
        'logged-out-padding': !loggedInPadding
      }"
    >
      <div
        *ngIf="dealerLoggedIn$ | async; else publicPage"
        class="container-lg my-3"
      >
        <router-outlet></router-outlet>
      </div>
      <ng-template #publicPage>
        <div class="container-lg mt-5 pt-4 mb-2">
          <router-outlet></router-outlet>
        </div>
      </ng-template>
    </main>
  </mdb-sidenav-content>
</mdb-sidenav-layout>
