<div>
  <div id="top-section" style="height: 50% !important">
    <tags-carousel></tags-carousel>
  </div>

  <div class="row g-3 gy-md-0 gx-md-4 my-4">
    <div class="col-12 col-md-4">
      <div class="card h-100 text-center">
        <img
          src="../../../assets/icons/serviceicons_0000_Vector-Smart-Object.png"
          style="width: 150px; height: 150px"
          class="card-img-top align-self-center mt-3"
        />
        <div class="card-body tags-text-secondary">
          <!-- <mdb-icon fas icon="key" size="5x" class="mb-2"></mdb-icon> -->
          <h5 class="card-title tags-text-primary font-weight-bold">
            New Car Services
          </h5>
          <mdb-accordion [borderless]="true">
            <mdb-accordion-item>
              <ng-template mdbAccordionItemHeader class="card-text">
                Fleet Dealer Registration (FDR)
              </ng-template>
              <ng-template mdbAccordionItemBody>
                TAGS is pleased to offer a software registration program which
                allows dealers, rental car agencies and fleet management
                companies the ability to register new cars on-site with a safe,
                secure website.<br /><br />
                In turn, rent-a-car locations, new car dealers, and other
                organizations can license their vehicles without waiting in line
                at the DMV.<br /><br />
                Our FDR system has been approved by The Motor Vehicle Licensing
                and Permits Division of the City and County of Honolulu, the
                State of Hawaii and Hawaii Department of Transportation. To
                utilize this state-of-the art program, your organization enters
                into a contractual agreement with Tags as a third-party
                vendor.<br /><br />
                License plates are issued directly to your organization via DMV
                and registration printers and thermal registration cards are
                coordinated through TAGS. Once you're approved, we oversee all
                aspects of training and customer support when needed. We will
                always value you and your client's confidentiality and strive to
                maintain a highly effective and secure program.
              </ng-template>
            </mdb-accordion-item>
            <mdb-accordion-item>
              <ng-template mdbAccordionItemHeader class="card-text">
                Conventional Titles and Registration
              </ng-template>
              <ng-template mdbAccordionItemBody>
                TAGS Hawaii Inc. offers conventional New Car title and
                registration solutions meaning we deliver paperwork manually to
                the DMV for processing.<br /><br />
                With step-by-step guidance from TAGS you the client will be able
                to supply all necessary vehicle documentation along with
                required notarized documents from financial institutions and or
                lien holders. TAGS will then process the remaining information
                and submit to the Motor Vehicle Licensing and Permits Division
                of the City and County of Honolulu or outer island counties as
                required.<br /><br />
                Once all documents are completed by the City and County of
                Honolulu, Tags will verify the transaction's accuracy and return
                the work to the dealership.<br /><br />
                Once all documents are completed, TAGS verifies the
                transaction's accuracy and returns the work to you, the client.
              </ng-template>
            </mdb-accordion-item>
          </mdb-accordion>
          <button
            type="button"
            size="sm"
            mdbWavesEffect
            (click)="openContactModal()"
            class="btn tags-primary-color-bg text-white my-1 z-depth-0"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-4">
      <div class="card h-100 text-center">
        <img
          src="../../../assets/icons/serviceicons_0001_Vector-Smart-Object.png"
          style="width: 150px; height: 150px"
          class="card-img-top align-self-center mt-3"
        />

        <div class="card-body tags-text-secondary">
          <!-- <mdb-icon fas icon="key" size="5x" class="mb-2"></mdb-icon> -->
          <h5 class="card-title tags-text-primary font-weight-bold">
            Used Car Services
          </h5>
          <mdb-accordion [borderless]="true">
            <mdb-accordion-item>
              <ng-template mdbAccordionItemHeader class="card-text">
                Fleet Dealer Registration (FDR) Renewals
              </ng-template>
              <ng-template mdbAccordionItemBody>
                TAGS FDR system offers fleet renewals for those clients that
                have been certified by the State of Hawaii. Renewals can be
                processed and printed at your desk if they are all under the
                same business name. For more information on being certified
                please contact TAGS.
              </ng-template>
            </mdb-accordion-item>
            <mdb-accordion-item>
              <ng-template mdbAccordionItemHeader="" class="card-text"
                >Additional Services</ng-template
              >
              <ng-template mdbAccordionItemBody="">
                TAGS offers a broad range of traditional used car services
                including but not limited to:<br /><br />
                • Transfers<br />
                • Duplicate titles<br />
                • Salvage titles<br />
                • Registration renewals<br />
                • Storage titles<br />
                • Lien interest filing
              </ng-template>
            </mdb-accordion-item>
            <mdb-accordion-item>
              <ng-template mdbAccordionItemHeader class="card-text"
                >Special Projects</ng-template
              >
              <ng-template mdbAccordionItemBody>
                TAGS is Hawaii's leading specialist when it comes to handling
                large volume projects. Especially when one business has been
                bought out by an another and all titles must be changed within a
                specific time.
              </ng-template>
            </mdb-accordion-item>
            <mdb-accordion-item>
              <ng-template mdbAccordionItemHeader [class="card-text"
                >Insurance Salvage</ng-template
              >
              <ng-template mdbAccordionItemBody>
                TAGS is Hawaii's leading specialist in the following for local
                and mainland Insurance companies:<br /><br />
                • Salvage<br />
                • Storage<br />
                • Duplicate titles<br />
                • Repossessions
              </ng-template>
            </mdb-accordion-item>
          </mdb-accordion>
          <button
            type="button"
            size="sm"
            mdbWavesEffect
            (click)="openContactModal()"
            class="btn tags-primary-color-bg text-white my-1 z-depth-0"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-4">
      <div class="card h-100 text-center">
        <img
          src="../../../assets/icons/serviceicons_0002_Vector-Smart-Object.png"
          style="width: 150px; height: 150px"
          class="card-img-top align-self-center mt-3"
        />

        <div class="card-body tags-text-secondary">
          <!-- <mdb-icon fas icon="key" size="5x" class="mb-2"></mdb-icon> -->
          <h5 class="card-title tags-text-primary font-weight-bold">
            Other Car Services
          </h5>
          <mdb-accordion [borderless]="true">
            <mdb-accordion-item>
              <ng-template mdbAccordionItemHeader class="card-text"
                >Fleet Management</ng-template
              >
              <ng-template mdbAccordionItemBody
                >Tags offers a comprehensive system for management of your
                entire fleet. We process and manage registration, titles,
                renewals, VIC cards, transfers, and heavy vehicle certificates
                efficiently and accurately.</ng-template
              >
            </mdb-accordion-item>
            <mdb-accordion-item
              ><ng-template mdbAccordionItemHeader class="card-text"
                >Dealer Reports</ng-template
              >
              <ng-template mdbAccordionItemBody
                >Tags maintains a comprehensive database for easy access to all
                records. This includes all information pertaining to each
                processed transaction. Our team is able to provide information
                quickly with a phone call or email.</ng-template
              ></mdb-accordion-item
            >
            <mdb-accordion-item
              ><ng-template mdbAccordionItemHeader class="card-text"
                >Notary Services</ng-template
              >
              <ng-template mdbAccordionItemBody
                >Tags offers notary services to both existing customers and the
                general public daily from 9 to 11 AM, Monday thru Friday. For
                existing customers this service is complimentary while there is
                a nominal fee for the general public.</ng-template
              ></mdb-accordion-item
            >
          </mdb-accordion>
          <button
            type="button"
            size="sm"
            mdbWavesEffect
            (click)="openContactModal()"
            class="btn tags-primary-color-bg text-white my-1 z-depth-0"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row g-4">
    <div class="col-12 col-sm-6 col-md-3">
      <div class="card h-100">
        <img
          src="../../../assets/bottomimages/bottomimages_0002_anydevice.jpg"
          class="card-img-top"
        />
        <div class="card-body tags-secondary-color-bg text-white">
          <h4 class="card-title h4-responsive font-weight-bold mb-4">
            No Special Hardware Required
          </h4>
          <p class="card-text text-white">
            Use your existing equipment to login to our secure site for instant
            access to register new vehicle tags.
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3">
      <div class="card h-100">
        <img
          src="../../../assets/bottomimages/bottomimages_0001_experience.jpg"
          class="card-img-top"
        />
        <div class="card-body tags-secondary-color-bg text-white">
          <h4 class="card-title h4-responsive font-weight-bold mb-4">
            40+ Years of Experience
          </h4>
          <p class="card-text text-white">
            We are industry leaders in Hawaii for vehicle tag registration and
            pioneered electronic access to new tags from your location.
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3">
      <div class="card h-100">
        <img
          src="../../../assets/bottomimages/bottomimages_0003_management.jpg"
          class="card-img-top"
        />
        <div class="card-body tags-secondary-color-bg text-white">
          <h4 class="card-title h4-responsive font-weight-bold mb-4">
            Inventory Management
          </h4>
          <p class="card-text text-white">
            The online system together with our desktop based vehicle
            registration service can manage your fleet tags.
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3">
      <div class="card h-100">
        <img
          src="../../../assets/bottomimages/bottomimages_0000_current.jpg"
          class="card-img-top"
        />
        <div class="card-body tags-secondary-color-bg text-white">
          <h4 class="card-title h4-responsive font-weight-bold mb-4">
            Always Current
          </h4>
          <p class="card-text text-white">
            We'll keep you current on changes to Hawaii DMV registration
            requirements.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
