<div class="modal-header">
  <h5 class="modal-title h3-responsive font-weight-bold" id="contactModalLabel">
    Contact Us
  </h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="contactModal.close()"
  ></button>
</div>
<form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="row">
      <div class="col-4 text-center">
        <i class="fas fa-map-marked fa-3x tags-primary-color pb-2"></i><br />
        <a href="https://goo.gl/maps/Gsu3JfaW7KDNdUSr8" class="black-text">
          <strong>
            98-029 Hekaha Street,<br />
            Bldg 5 Suite 20<br />
            Aiea, HI 96701, USA
          </strong>
        </a>
      </div>
      <div class="col-4 text-center">
        <i class="fas fa-phone fa-3x tags-secondary-color pb-2"></i><br />
        <a href="tel:808-485-8247"
          ><strong class="black-text">+1 (808) 485 - 8247</strong></a
        >
      </div>
      <div class="col-4 text-center">
        <i class="fas fa-envelope fa-3x tags-tertiary-color pb-2"></i><br />
        <a href="mailto:info@tagshi.com"
          ><strong class="black-text">info@tagshi.com</strong></a
        >
      </div>
      <!-- <hr class="hr-light my-4" />
      <ul class="list-inline text-center list-unstyled">
        <li class="list-inline-item">
          <a
            class="p-2 fa-lg tw-ic"
            href="https://www.facebook.com/Tags-Hawaii-Inc-529750720427149/"
            target="_blank"
          >
            <mdb-icon fab icon="facebook-f"></mdb-icon>
          </a>
        </li>
        <li class="list-inline-item">
          <a
            class="p-2 fa-lg li-ic"
            href="https://www.linkedin.com/company/tags-hawaii-inc/"
            target="_blank"
          >
            <mdb-icon fab icon="linkedin-in"> </mdb-icon>
          </a>
        </li>
        <li class="list-inline-item">
          <a
            class="p-2 fa-lg ins-ic"
            href="https://www.bbb.org/us/hi/aiea/profile/auto-registration/tags-hawaii-inc-1296-1367"
            target="_blank"
          >
            <mdb-icon fas icon="globe-americas"> </mdb-icon>
          </a>
        </li>
      </ul> -->
    </div>
    <hr />
    <div class="row">
      <div class="col-5 mb-4">
        <label mdbLabel class="form-label mb-0" for="contactFormName"
          >Your Name</label
        >
        <mdb-form-control>
          <input
            #contactName
            mdbValidate
            mdbInput
            type="text"
            formControlName="name"
            id="contactFormName"
            class="form-control"
            autocomplete="name"
            minlength="1"
            [ngClass]="{ 'is-invalid': submitted && contact.name.errors }"
            [tagsFormControlStatus]="contactForm.get('name')"
          />
        </mdb-form-control>
      </div>
      <div class="col-7 mb-4">
        <label mdbLabel class="form-label mb-0" for="contactFormEmail">Email</label>
        <mdb-form-control>
          <input
            mdbInput
            mdbValidate
            type="text"
            formControlName="email"
            id="contactFormEmail"
            class="form-control"
            autocomplete="email"
            [ngClass]="{ 'is-invalid': submitted && contact.email.errors }"
            [tagsFormControlStatus]="contactForm.get('email')"
          />
        </mdb-form-control>
      </div>
      <div class="col-12 mb-4">
        <label mdbLabel class="form-label mb-0" for="contactFormSubject"
          >Subject</label
        >
        <mdb-form-control>
          <input
            mdbInput
            mdbValidate
            type="text"
            formControlName="subject"
            id="contactFormSubject"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && contact.subject.errors }"
            [tagsFormControlStatus]="contactForm.get('subject')"
          />
        </mdb-form-control>
      </div>
      <div class="col-12">
        <div class="mb-4">
          <label mdbLabel class="form-label mb-0" for="contactFormMessage"
            >Message</label
          >
          <mdb-form-control>
            <textarea
              mdbInput
              mdbValidate
              rows="4"
              formControlName="message"
              id="contactFormMessage"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && contact.message.errors }"
            ></textarea>
            <mdb-error
              *ngIf="
                contact.message.hasError('required') && contact.message.touched
              "
              >Required
            </mdb-error>
          </mdb-form-control>
        </div>
        <div class="form-check mb-4">
          <input
            mdbCheckbox
            class="form-check-input"
            type="checkbox"
            id="contactFormSendCopy"
            formControlName="sendCopy"
          />
          <label class="form-check-label" for="contactFormSendCopy">
            Send a copy of this message to your inbox?
          </label>
        </div>
        <re-captcha
          siteKey="{{ siteKey }}"
          formControlName="recaptcha"
          required
        ></re-captcha>
      </div>
    </div>

    <div *ngIf="isError">
      <strong class="red-text">Form is invalid.</strong>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-danger"
      (click)="contactModal.close()"
      mdbRipple
    >
      Close
    </button>
    <button
      [disabled]="loading || contactForm.invalid"
      type="submit"
      class="btn btn-success"
      mdbRipple
    >
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
      Send
    </button>
  </div>
</form>
