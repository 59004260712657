<div class="modal-header">
  <h5 class="modal-title h3-responsive font-weight-bold" id="loginModalLabel">
    Login
  </h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="loginModal.close()"
  ></button>
</div>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div>
      <mdb-form-control>
        <input
          #dealerCodeInput
          mdbInput
          mdbValidate
          type="text"
          formControlName="dealerCode"
          id="loginDealerCode"
          class="form-control mb-0"
          autocomplete="additional-name"
          placeholder="Dealer Code"
          minlength="4"
          maxlength="5"
          oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
          [ngClass]="{ 'is-invalid': submitted && login.dealerCode.errors }"
        />
        <!-- <label mdbLabel class="form-label" for="loginDealerCode"
        >Dealer Code</label
      > -->
        <control-messages
          [control]="loginForm.get('dealerCode')"
          class="m-0 p-0"
        ></control-messages>
      </mdb-form-control>
    </div>

    <div class="my-4">
      <mdb-form-control>
        <input
          mdbInput
          mdbValidate
          type="text"
          formControlName="username"
          id="loginUsername"
          class="form-control mb-0"
          autocomplete="username"
          placeholder="Username"
          [ngClass]="{ 'is-invalid': submitted && login.username.errors }"
        />
        <!-- <label mdbLabel class="form-label" for="loginUsername">Username</label> -->
        <control-messages
          [control]="loginForm.get('username')"
          class="m-0 p-0"
        ></control-messages>
      </mdb-form-control>
    </div>

    <div>
      <mdb-form-control>
        <input
          mdbInput
          mdbValidate
          type="password"
          formControlName="password"
          id="loginPassword"
          class="form-control"
          autocomplete="current-password"
          placeholder="Password"
          [ngClass]="{ 'is-invalid': submitted && login.password.errors }"
        />
        <!-- <label mdbLabel class="form-label" for="loginPassword">Password</label> -->
        <control-messages
          [control]="loginForm.get('password')"
          class="m-0 p-0"
        ></control-messages>
      </mdb-form-control>
    </div>

    <div class="mt-4">
      <re-captcha
        siteKey="{{ siteKey }}"
        formControlName="recaptcha"
        (resolved)="resolved($event)"
      ></re-captcha>
    </div>

    <div *ngIf="isError">
      <strong class="text-danger"
        >Incorrect code, username, or password.</strong
      >
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="loginModal.close()">
      Close
    </button>
    <button
      [disabled]="loading || loginForm.invalid"
      type="submit"
      class="btn btn-success"
    >
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
      Login
    </button>
  </div>
</form>
