import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class TagsInterceptor implements HttpInterceptor {
  private loginToken: string;

  constructor(private authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authRequest = request.clone({ withCredentials: true });
    //console.log('Request Authenticated withCredentials');
    return next.handle(authRequest);
    // if (!request.url.includes('ContactEmail')) {
    //   const authString = 'Basic ' + this.authService.currentAuthHeader;
    //   // console.log('Auth Header in Interceptor: ' + authString);
    //   const authenticatedRequest = request.clone({
    //     headers: new HttpHeaders({
    //       Authorization: authString,
    //     }),
    //   });

    //   // console.log(authenticatedRequest);
    //   return next.handle(authenticatedRequest);
    // } else {
    //   return next.handle(request);
    // }
  }
}
