import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import {
  MdbNotificationRef,
  MdbNotificationService,
} from 'mdb-angular-ui-kit/notification';
import { DealerProfile } from 'src/app/_helpers/dealer-profile';
import { NotificationComponent } from 'src/app/_shared/notification/notification.component';

@Component({
  // tslint:disable: component-selector
  selector: 'tags-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dealerCodeInput') dealerCodeField: ElementRef<HTMLInputElement>;
  private toastService = inject(MdbNotificationService);
  private notificationRef: MdbNotificationRef<NotificationComponent> | null =
    null;
  siteKey = '6Lc-F0AeAAAAAAy3LWfTuwIOWPyn4Fg8N1omW_Zy';
  subs: Subscription;
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  isError = false;
  isFinished = false;
  returnUrl: string;
  error: string;

  constructor(
    public loginModal: MdbModalRef<LoginComponent>,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    // redirect to profile if already logged in
    if (this.authenticationService.loggedIn) {
      this.router.navigate(['/account']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      dealerCode: ['', [Validators.required, Validators.pattern('[A-Z]+')]],
      username: ['', Validators.required],
      password: ['', Validators.required],
      recaptcha: ['', Validators.required],
    });

    const returnUrlString = 'returnUrl';
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams[returnUrlString] || '/';
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    // Timeout is needed to autofocus the field after the modal
    // finishes animating into view. 351ms is the exact amount
    // needed for a seamless transition.
    setTimeout(() => {
      // Autofocus the dealer code field upon page load.
      this.focusDealerCode();
    }, 351);
  }

  focusDealerCode() {
    if (this.dealerCodeField) {
      this.dealerCodeField.nativeElement.focus();
    }
  }

  async resolved(captchaResponse: string) {
    //await this.sendTokenToBackend(captchaResponse);
  }

  // sendTokenToBackend(tok) {
  //   //calling the service and passing the token to the service
  //   this.recaptcha.sendToken(tok).subscribe({
  //     next: (data) => {
  //       console.log(data);
  //     },
  //     error: (err) => {
  //       console.log(err);
  //     },
  //     complete: () => {},
  //   });
  // }

  // convenience getter for easy access to form fields
  get login() {
    return this.loginForm.controls;
  }

  showLoggedIn() {
    this.notificationRef = this.toastService.open(NotificationComponent, {
      autohide: true,
      delay: 3500,
      stacking: true,
      animation: true,
      data: {
        header: 'Login Success',
        text: 'You are now logged in.',
        severity: 'success',
      },
    });
    // tslint:disable-next-line: quotemark
    //this.toastService.success("You've logged in!", 'Welcome!', options);
  }

  onSubmit() {
    // continue only if the form is valid
    if (this.loginForm.valid) {
      this.submitted = true;
      this.loading = true;

      this.subs = this.authenticationService
        .login(
          this.loginForm.value.dealerCode,
          this.loginForm.value.username,
          this.loginForm.value.password,
          this.loginForm.value.recaptcha
        )
        .subscribe({
          next: (data: DealerProfile) => {
            sessionStorage.setItem('dealerProfile', JSON.stringify(data));
            this.authenticationService.postLogin(data);

            this.loading = false;
            this.isError = false;
            this.loginModal.close();
            this.showLoggedIn();
            this.router.navigate(['/account']);
          },
          error: (err) => {
            this.isError = true;
            console.error('LOGIN ERROR: ', err);
            this.loading = false;
            this.authenticationService.failed();
          },
          complete: () => {
            this.isFinished = true;
            this.loginForm.reset();
          },
        });
    }
  }
}
