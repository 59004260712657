<div
  *ngIf="isBrowser(); else staticImage"
  class="carousel-inner rounded-5 shadow-4-strong"
>
  <mdb-carousel
    [controls]="false"
    [indicators]="true"
    [animation]="'fade'"
    [interval]="'5000'"
  >
    <mdb-carousel-item>
      <img
        class="d-block w-100"
        src="../../../assets/headerimages/headerimages_0001_car.jpg"
        alt="First slide"
      />
      <div
        [ngClass]="{ 'carousel-caption-lg': isPortrait() }"
        class="carousel-caption"
      >
        <div
          class="animated fadeInDown text-center text-lg-left p-2"
          style="background-color: rgba(0, 0, 0, 0.5)"
        >
          <h1
            class="pb-2 font-weight-bold display-4-responsive tags-secondary-color tags-title"
          >
            The TAGS Difference
          </h1>
          <p class="text-white">
            For 40+ years, Tags has been providing outstanding customer service
            to all the islands of Hawai'i. We continue to operate using these
            same Aloha founding principles today. We combine local experience
            and excellent customer service to deliver fast and accurate title
            services island wide.
            <br />
            <button
              type="button"
              size="sm"
              mdbWavesEffect
              (click)="openContactModal()"
              class="btn tags-primary-color-bg text-white ml-lg-0 z-depth-0"
            >
              Learn More
            </button>
          </p>
        </div>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <img
        class="d-block w-100"
        src="../../../assets/headerimages/headerimages_0000_skyline.jpg"
        alt="Second slide"
      />
      <div
        [ngClass]="{ 'carousel-caption-lg': isPortrait() }"
        class="carousel-caption"
      >
        <div
          class="animated fadeInDown text-center text-lg-left p-2"
          style="background-color: rgba(0, 0, 0, 0.5)"
        >
          <h1
            class="pb-2 font-weight-bold display-4-responsive tags-secondary-color tags-title"
          >
            The TAGS Ethics
          </h1>
          <p class="text-white">
            Here at Tags, acting ethically is part of our DNA - it is who we
            are, and it is what our customers and partners expect from us at
            every turn. We strive to create and maintain long lasting
            relationships with partners who share our values and our commitment
            to doing the right thing.
            <br />
            <button
              type="button"
              size="sm"
              mdbWavesEffect
              (click)="openContactModal()"
              class="btn tags-primary-color-bg text-white ml-lg-0 z-depth-0"
            >
              Learn More
            </button>
          </p>
        </div>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <img
        class="d-block w-100"
        src="../../../assets/headerimages/headerimages_003.jpg"
        alt="Third slide"
      />
      <div
        [ngClass]="{ 'carousel-caption-lg': isPortrait() }"
        class="carousel-caption"
      >
        <div
          class="animated fadeInDown text-center text-lg-left p-2"
          style="background-color: rgba(0, 0, 0, 0.5)"
        >
          <h1
            class="pb-2 font-weight-bold display-4-responsive tags-secondary-color tags-title"
          >
            The TAGS Relationship
          </h1>
          <p class="text-white">
            We build the foundation for a lasting relationship, identifying your
            company's business goals and tailoring services just to meet your
            needs. Let's discover together what's next for your company. We can
            help you a little or a lot, you decide!
            <br />
            <button
              type="button"
              size="sm"
              mdbWavesEffect
              (click)="openContactModal()"
              class="btn tags-primary-color-bg text-white ml-lg-0 z-depth-0"
            >
              Learn More
            </button>
          </p>
        </div>
      </div>
    </mdb-carousel-item>
  </mdb-carousel>
</div>

<ng-template #staticImage>
  <img
    class="d-block w-100"
    src="../../../assets/headerimages/headerimages_0001_car.jpg"
    alt="First slide"
  />
</ng-template>
