import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tags-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
})
export class TeamComponent implements OnInit {
  testimonials: any = [
    {
      name: 'Anita Madamba',
      position: 'Office Manager',
      description: `Anita has been at Tags since it's inception. She has watched it evolve into the dynamic business it is today and can step in and "run the business" at any moment.
      
      She understands every aspect of titling for all counties here in the State of Hawaii. This along with her strong finance background in accounts payable and receivable makes her a valuable Office Manager.`,
      avatar: '../../../assets/staff/stafficons/anita.png',
      socials: [
        {
          link: 'https://linkedin.com',
          icon: 'linkedin-in',
          style: 'li-ic',
        },
      ],
    },
    {
      name: 'Jessica Sultzer',
      position: 'Senior Title Specialist',
      description: `Jessica has a wealth of knowledge in the title industry, having worked for many local dealerships prior to coming to Tags. Her key roles with Tags are FDR IT support along with local and mainland Fleet Management.
      
      Jessica's number one priority is delivering excellent customer service by utilizing her impeccable time management skills.`,
      avatar: '../../../assets/staff/stafficons/Jessica.png',
      socials: [
        {
          link: 'https://linkedin.com',
          icon: 'linkedin-in',
          style: 'li-ic',
        },
      ],
    },
    {
      name: 'Alison Peter',
      position: 'Senior Title Specialist',
      description: `Alison has been with Tags for over 10 years and another 10 at other dealerships.
      
      Her excellent title experience, warm personality combined with her focus on local dealerships, has created close relationships with all Tags clients.`,
      avatar: '../../../assets/staff/stafficons/Alison.png' /* CHANGE AVATAR */,
      socials: [
        {
          link: 'https://linkedin.com',
          icon: 'linkedin-in',
          style: 'li-ic',
        },
      ],
    },
    {
      name: 'Evelyn Kauhola',
      position: 'Senior Title Specialist',
      description: `Evelyn has over 15 years of title / DMV experience and has been with Tags for 4 years. Her expertise is with mainland and local dealerships.
        
        Evelyn enjoys "working with clients from other states and helping them with their DMV needs, while meeting or exceeding client expectations."`,
      avatar: '../../../assets/staff/stafficons/evelyn2.png',
      socials: [
        {
          link: 'https://linkedin.com',
          icon: 'linkedin-in',
          style: 'li-ic',
        },
      ],
    },
    {
      name: 'Janna Samia',
      position: 'Senior Title Specialist',
      description: `Janna's passion and attention to detail makes her a fabulous fit for managing and overseeing our Insurance Salvage division and mainland fleet management.
      
      She enjoys working closely with her clients to fulfill their individual car tag and registration needs.`,
      avatar: '../../../assets/staff/stafficons/janna-alt.png',
      socials: [
        {
          link: 'https://linkedin.com',
          icon: 'linkedin-in',
          style: 'li-ic',
        },
      ],
    },
    {
      name: 'Tanaya Vitale-Vae',
      position: 'Administrative Support / Courier',
      description: `Tanaya's bright and bubbly personality makes her the perfect person to deliver and collect a variety of title documents.
      
      Always eager to get in and lend a hand, we are truly grateful to have her on our team.`,
      avatar: '../../../assets/staff/stafficons/tanaya.png',
      socials: [
        {
          link: 'https://linkedin.com',
          icon: 'linkedin-in',
          style: 'li-ic',
        },
      ],
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
