import { JwtModule } from '@auth0/angular-jwt';

import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbSidenavModule } from 'mdb-angular-ui-kit/sidenav';
import { MdbSmoothScrollModule } from 'mdb-angular-ui-kit/smooth-scroll';
import { MdbTableModule } from 'mdb-angular-ui-kit/table';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbScrollbarModule } from 'mdb-angular-ui-kit/scrollbar';

import { CardsModule } from 'ng-uikit-pro-standard';

import { AppComponent } from './app.component';
import { NavigationModule } from './main-layout/navigation.module';
import { AppRoutingModule } from './app-routes.module';
import { SharedModule } from './_shared/shared.module';
import { httpInterceptorProviders } from './_helpers/index';
import {
  NgModule,
  NO_ERRORS_SCHEMA,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  APP_INITIALIZER,
} from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ValidationService } from './_services/validation.service';
import { LoginModule } from './views/login/login.module';
import { RecaptchaLoaderService } from 'ng-recaptcha';
import { HomeComponent } from './views/home/home.component';
import { AboutComponent } from './views/home/about/about.component';
import { TeamComponent } from './views/home/team/team.component';
import { FaqComponent } from './views/home/faq/faq.component';
import { AccreditationsPageComponent } from './views/home/accreditations/accreditations-page.component';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { MeetTheCeoComponent } from './views/home/meet-the-ceo/meet-the-ceo.component';
import { TagsTipsComponent } from './views/home/tags-tips/tags-tips.component';
import { getHeader } from './_services';
import { DevComponent } from './views/dev/dev.component';
import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    TeamComponent,
    FaqComponent,
    AccreditationsPageComponent,
    MeetTheCeoComponent,
    TagsTipsComponent,
    DevComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CardsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MdbAccordionModule,
    MdbCollapseModule,
    MdbDatepickerModule,
    MdbFormsModule,
    MdbRippleModule,
    MdbScrollbarModule,
    MdbSidenavModule,
    MdbSmoothScrollModule,
    MdbTableModule,
    NavigationModule,
    SharedModule,
    // AgmCoreModule.forRoot({
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
    //   apiKey: 'AIzaSyBfwgU-1FdqdEWyUUruMtF0xdgnTe0TwEY',
    // }),
    JwtModule.forRoot({
      config: {
        tokenGetter: getHeader,
        allowedDomains: ['localhost:4200', 'https://dev.tagshi.com'],
      },
    }),
    AppRoutingModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    httpInterceptorProviders,
    provideClientHydration(),
    RecaptchaLoaderService,
    ValidationService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
