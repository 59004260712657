import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tags-accreditations-page',
  templateUrl: './accreditations-page.component.html',
  styleUrls: ['./accreditations-page.component.scss'],
})
export class AccreditationsPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
