import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      required: 'Required',
      usDate: 'Invalid date',
      invalidCreditCard: 'Is invalid credit card number',
      invalidEmailAddress: 'Invalid email address',
      invalidPassword:
        'Invalid password. Password must be at least 6 characters long and contain a number',
      minlength: `Minimum length ${validatorValue.requiredLength}`,
      onlyDigitAllowed: 'Only Capital Letters and Digits are allowed',
      max: `Maximum value ${validatorValue.max}`,
    };

    return config[validatorName];
  }

  regexValidator(nameRe: RegExp, errorMsg?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = nameRe.test(control.value);
      return !forbidden && control.value
        ? { onlyDigitAllowed: { value: control.value, errorMessage: errorMsg } }
        : null;
    };
  }

  // static passwordValidator(control) {
  //   // {6,100}           - Assert password is between 6 and 100 characters
  //   // (?=.*[0-9])       - Assert a string has at least one number
  //   if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
  //     return null;
  //   } else {
  //     return { invalidPassword: true };
  //   }
  // }
}
