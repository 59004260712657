import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DateTime } from 'luxon';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tags-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class AppFooterComponent implements OnInit {
  version: string = environment.version;
  year: number;

  constructor(@Inject(PLATFORM_ID) private platformID: any) {}

  ngOnInit() {
    this.year = DateTime.now().year;
    if (isPlatformBrowser(this.platformID)) {
    } else if (isPlatformServer(this.platformID)) {
    }
  }
}
