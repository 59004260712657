<!-- Section heading -->
<div>
  <div class="card">
    <div class="card-header card-header-primary text-white text-center">
      <h1 class="h1-responsive text-center"><strong>Our Tips</strong></h1>
    </div>
    <div class="card-body">
      <div class="row g-3">
        <div class="col-12 col-lg-6">
          <div class="card h-100">
            <h3
              class="card-header card-header-secondary text-white text-center"
            >
              <strong
                >4 Most Common Vehicle<br />Registration and Title Errors</strong
              >
            </h3>
            <div class="card-body">
              <p>
                When it comes to title and registration, accuracy and
                consistency are both key, especially for companies that need
                large volumes of vehicle titles and registrations processed
                quickly. To guide you the TAGS team has compiled the top five
                errors they see when helping partners with their
                title-and-registration work, as well as tips on how to avoid
                these frequently made mistakes.
              </p>
              <ol class="list-group list-group-light list-group-numbered">
                <li
                  class="list-group-item d-flex justify-content-between align-items-start"
                >
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Expired Documents</div>
                    Oftentimes, our partners have safety inspections that expire
                    before registration renewals can be processed. This most
                    often occurs when either the driver or the company in
                    question experiences delays in collecting and submitting the
                    required paperwork. In many cases, when this paperwork is
                    late, it actually expires within days of a
                    title-and-registration provider like TAGS receiving it.<br />
                    The State of Hawaii will not accept expired safety
                    inspections and will reject the work from being completed.
                    They will however accept a failed safety (which is valid for
                    only 30 days and can be for registration expiry only).
                  </div>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-start"
                >
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Incorrect VINs</div>
                    If the VIN listed on the title document does not match the
                    VIN on the bill of sale, cover sheet or the safety
                    inspection, this can lead to rejections from the DMV, this
                    can further delay processing or risk processing incorrectly.
                    When the DMV processes the work incorrectly due to an
                    incorrect VIN, additional costs will be incurred due to the
                    extra work of having to complete a correction on
                    title-and-registration paperwork.<br />
                    To avoid incorrect VINs, our partners should review all
                    documentation to ensure VINs are accurate and consistent
                    throughout.<br />
                    If a VIN is incorrect on the title, a company will first
                    have to obtain a title correction through the respective
                    state before proceeding. TAGS can help with title
                    corrections.
                  </div>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-start"
                >
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Incorrect Mileage</div>
                    If the mileage listed on the title is different than the
                    mileage reported at the time of the sale or transfer, this
                    can lead to rejection by DMV, as well as delays in
                    processing.<br />
                    Hawaii State counties require that the vehicle title be
                    corrected with a notarized odometer correction form.<br />
                    To avoid incorrect mileage, companies should review all
                    documentation to ensure mileage is accurate and consistent
                    throughout.
                  </div>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-start"
                >
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Incorrect Owner Name/s</div>
                    If the owner name/s on the title does not match the buyer's
                    name on the bill of sale, this can lead to a rejection by
                    DMV. To avoid an incorrect owner name, please review all
                    documentation to ensure the owner name/s are accurate.<br />
                    If the owner name/s are incorrect on the title reassignment,
                    please reach out to TAGS for assistance as each deal will be
                    unique.<br />
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card h-100">
            <h3
              class="card-header card-header-secondary text-white text-center"
            >
              <strong>Title and Registration<br />Terms Decoded</strong>
            </h3>
            <div class="card-body">
              <p>
                When it comes to vehicle title and registration, there is no
                shortage of relevant terms and acronyms. To help companies
                become more familiar with regularly used title-and-registration
                terminology, the TAGS team has decoded many of these terms and
                compiled a list for easy reference.
              </p>
              <div>
                <mdb-form-control>
                  <input
                    mdbInput
                    type="text"
                    class="form-control"
                    id="search-input"
                    (keyup)="search($event)"
                  />
                  <label mdbLabel class="form-label" for="search-input"
                    >Search codes</label
                  >
                </mdb-form-control>
                <div class="datatable mt-4">
                  <div
                    class="datatable-inner"
                    mdbScrollbar
                    style="position: relative; max-height: 1000px"
                  >
                    <table
                      class="table datatable-table"
                      mdbTable
                      mdbTableSort
                      #codeTable="mdbTable"
                      #codeSort="mdbTableSort"
                      [dataSource]="codeList"
                      [sort]="codeSort"
                    >
                      <thead class="datatable-header">
                        <tr>
                          <th
                            *ngFor="let header of headers"
                            [mdbTableSortHeader]="header"
                            scope="col"
                          >
                            {{ header | titlecase }}
                          </th>
                        </tr>
                      </thead>
                      <tbody class="datatable-body">
                        <tr *ngFor="let cell of codeTable.data" scope="row">
                          <td>
                            <p class="fw-bold">{{ cell.code }}</p>
                          </td>
                          <td>
                            <p style="white-space: normal">
                              {{ cell.description }}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
