import { ContactUsService } from './../../_services/contact-us.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { RecaptchaTestService } from '../../_services/recaptcha-test.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'tags-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit, AfterViewInit {
  @ViewChild('contactName') name: ElementRef<HTMLInputElement>;
  contactForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  isError = false;
  isFinished = false;
  optionsSelect: Array<any>;
  siteKey = '6Lc-F0AeAAAAAAy3LWfTuwIOWPyn4Fg8N1omW_Zy';

  constructor(
    public contactModal: MdbModalRef<ContactFormComponent>,
    private recaptcha: RecaptchaTestService,
    private fb: UntypedFormBuilder,
    private contactUsService: ContactUsService
  ) {}

  ngOnInit() {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      sendCopy: [true],
      recaptcha: ['', Validators.required],
    });

    this.optionsSelect = [
      { value: 'Feedback', label: 'Feedback' },
      { value: 'Report a bug', label: 'Report a bug' },
      { value: 'Feature request', label: 'Feature request' },
      { value: 'Other stuff', label: 'Other stuff' },
    ];
  }

  ngAfterViewInit(): void {
    // Timeout is needed to autofocus the field after the modal
    // finishes animating into view. 351ms is the exact amount
    // needed for a seamless transition.
    setTimeout(() => {
      // Autofocus the dealer code field upon page load.
      this.focusContactName();
    }, 351);
  }

  focusContactName() {
    if (this.name) {
      this.name.nativeElement.focus();
    }
  }

  get contact() {
    return this.contactForm.controls;
  }

  onSubmit() {
    console.log('Email sending logic here.');
    this.loading = true;
    const details: any = this.contactForm.value;

    this.contactUsService.submitContactEmail(details).subscribe({
      next: (data) => {
        console.log('Email Sent! Response: ', data);
        this.contactForm.reset();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}
