import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, delay, forkJoin, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LookuplistsService {
  private lookupListsAPIEndpoint = environment.apiUrl + '/lookupLists';
  private http = inject(HttpClient);

  private getBodyTypes(): Observable<any> {
    const bodyTypesURL = this.lookupListsAPIEndpoint + '/bodyTypes';

    return this.http.get(bodyTypesURL);
  }

  private getColors(): Observable<any> {
    const colorsURL = this.lookupListsAPIEndpoint + '/colors';

    return this.http.get(colorsURL);
  }

  private getLienHolders(): Observable<any> {
    const lienURL = this.lookupListsAPIEndpoint + '/lienHolders';

    return this.http.get(lienURL);
  }

  private getMotivePowers(): Observable<any> {
    const motiveURL = this.lookupListsAPIEndpoint + '/motivePowers';

    return this.http.get(motiveURL);
  }

  private getTransmissions(): Observable<any> {
    const transmissionsURL = this.lookupListsAPIEndpoint + '/transmissions';

    return this.http.get(transmissionsURL);
  }

  private getUseTypes(): Observable<any> {
    const useTypesURL = this.lookupListsAPIEndpoint + '/useTypes';

    return this.http.get(useTypesURL);
  }

  private getVehicleMakes(): Observable<any> {
    const makesURL = this.lookupListsAPIEndpoint + '/vehicleMakes';

    return this.http.get(makesURL);
  }

  public fetchLists(): Observable<any> {
    // Create meta object to store all lookup lists
    let lists = {
      bodyTypes: this.getBodyTypes(),
      colors: this.getColors(),
      //lienHolders: this.getLienHolders(),
      motivePowers: this.getMotivePowers(),
      transmissions: this.getTransmissions(),
      useTypes: this.getUseTypes(),
      vehicleMakes: this.getVehicleMakes(),
    };

    // Fetch all lists at once
    return forkJoin(lists);
  }

  public searchLienHolders(filter: string): Observable<any> {
    const lienURL = `${this.lookupListsAPIEndpoint}/lienHolders?startingWith=${filter}`;

    return this.http.get(lienURL);
  }

  public getLienHolderInfo(code: string): Observable<any> {
    const lienURL = this.lookupListsAPIEndpoint + '/lienHolders/' + code;

    return this.http.get(lienURL);
  }
}
