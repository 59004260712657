import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { IAPIObject } from '../_helpers/vehicle-objects';

@Injectable({
  providedIn: 'root',
})
export class VehicleFormService {
  private vehiclesAPIEndpoint = environment.apiUrl + '/vehicles';

  constructor(private http: HttpClient) {}

  postVehicle(requestBody: string): Observable<any> {
    // Make a POST request
    return this.http.post(this.vehiclesAPIEndpoint, requestBody, {
      responseType: 'json',
    });
    // .pipe(retry(3), catchError(this.handleError));
  }

  getVehicleByID(vehicleID: string): Observable<IAPIObject> {
    // Make a GET request for a vehicle by ID
    const vehicleURL = this.vehiclesAPIEndpoint + '/' + vehicleID;
    console.log('getting vehicle...');
    return this.http.get<IAPIObject>(vehicleURL);
    // .pipe(retry(3), catchError(this.handleError));
  }

  getVehicle(searchForm: UntypedFormGroup): Observable<any> {
    // Make a GET request for a vehicle by ID
    let urlParams = '';

    if (searchForm.controls.licenseNo.value) {
      urlParams = '?licenseNo=' + searchForm.controls.licenseNo.value;
    }
    if (searchForm.controls.issueDate.value) {
      if (urlParams !== '') {
        urlParams += '&issueDate=' + searchForm.controls.issueDate.value;
      } else {
        urlParams = '?issueDate=' + searchForm.controls.issueDate.value;
      }
    }
    if (searchForm.controls.vin.value) {
      if (urlParams !== '') {
        urlParams += '&vin=' + searchForm.controls.vin.value;
      } else {
        urlParams = '?vin=' + searchForm.controls.vin.value;
      }
    }
    if (searchForm.controls.vehicleStatus.value) {
      if (urlParams !== '') {
        urlParams += '&status=' + searchForm.controls.vehicleStatus.value;
      } else {
        urlParams = '?status=' + searchForm.controls.vehicleStatus.value;
      }
    }
    if (searchForm.controls.stockNo.value) {
      if (urlParams !== '') {
        urlParams += '&stockNo=' + searchForm.controls.stockNo.value;
      } else {
        urlParams = '?stockNo=' + searchForm.controls.stockNo.value;
      }
    }

    const vehicleURL = this.vehiclesAPIEndpoint + urlParams;
    console.log(
      'Searching for Vehicle with the following parameters: ',
      urlParams
    );
    return this.http.get(vehicleURL);
    // .pipe(retry(3), catchError(this.handleError));
  }

  saveVehicleAsIs(carNumber: string, requestBody: string): Observable<any> {
    let url = this.vehiclesAPIEndpoint;

    if (carNumber !== '') {
      url += `/${carNumber}`;
      return this.http.put(url, requestBody, {
        responseType: 'json',
      });
    } else {
      return this.http.post(url, requestBody, {
        responseType: 'json',
      });
    }
  }

  putVehicle(vehicleID: string, vehicleDetail: any) {
    const url = `${this.vehiclesAPIEndpoint}/${vehicleID}`;

    return this.http.put(url, vehicleDetail);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return console.error('Something bad happened; please try again later.');
  }
}
