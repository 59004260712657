import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ValidationService } from '../../_services/validation.service';

@Component({
  selector: 'control-messages',
  templateUrl: './control-messages.component.html',
  styleUrls: ['./control-messages.component.scss']
})
export class ControlMessagesComponent {
  @Input() control: NgControl;
  @Input() showEllipsisEffect:boolean;

  constructor() {}

  get errorMessage() {
    let errors = null;
    for (let propertyName in this.control.errors) {

      if (
        this.control.errors.hasOwnProperty(propertyName) &&
        this.control.touched
      ) {
        if(this.control.errors[propertyName]?.errorMessage) {
          errors = this.control.errors[propertyName]?.errorMessage;
        } else {
          errors = ValidationService.getValidatorErrorMessage(
            propertyName,
            this.control.errors[propertyName]
          );
        }
      }

      if (
        this.control.errors.hasOwnProperty('error') &&
        !this.control.touched
      ) {
        errors = ValidationService.getValidatorErrorMessage(
          'error',
          this.control.errors['error']
        );
      }
    }
    return errors;
  }
}
