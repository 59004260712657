import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  private contactUsEndpoint: string = environment.apiUrl + '/ContactEmail';

  constructor(private http: HttpClient) {}

  submitContactEmail(contactDetails: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(this.contactUsEndpoint, contactDetails, httpOptions);
  }
}
