<div class="mt-5 pt-5">
  <div class="card">
    <div class="card-header card-header-primary text-white text-center">
      <h2 class="h1-responsive text-center font-weight-bold">
        Date Picker Test Page
      </h2>
    </div>
    <div class="card-body">
      <mdb-form-control class="my-4">
        <input
          mdbInput
          mdbValidate
          type="text"
          id="date-picker"
          formControlName="datePicker"
          class="form-control"
          [mdbDatepicker]="picker"
          (click)="picker.open()"
        />
        <label mdbLabel class="form-label" for="date-picker">Date Picker</label>
        <mdb-datepicker-toggle [mdbDatepicker]="picker"></mdb-datepicker-toggle>
        <mdb-datepicker
          [inline]="false"
          [confirmDateOnSelect]="true"
          [format]="'mm/dd/yyyy'"
          (dateChanged)="onDateChanged($event)"
          #picker
        ></mdb-datepicker>
        <control-messages
          [control]="datePickerForm.get('dateFrom')"
        ></control-messages>
      </mdb-form-control>

      <p>Current Date: {{ currentDate }}</p>
    </div>
  </div>
</div>
