<div>
  <div class="card">
    <div class="card-header card-header-primary text-white text-center">
      <h2 class="h1-responsive text-center font-weight-bold">
        Our Amazing Team
      </h2>
    </div>
    <h4 class="text-center text-gray m-5">
      Our Tags team has a combined 80+ years of title agency knowledge. Every
      member, including our CEO, is dedicated and thoroughly trained in all
      aspects of our services.
    </h4>
    <div class="card-body">
      <section class="text-center">
        <div class="row g-3">
          <div *ngFor="let testimony of testimonials" class="col-12 col-sm-12 col-md-6 col-xl-4">
            <mdb-card class="testimonial-card h-100">
              <div class="card-up tags-secondary-color-bg"></div>
              <!--Avatar-->
              <div class="avatar mx-auto">
                <mdb-card-img
                  [src]="testimony.avatar"
                  class="img-fluid rounded-circle"
                ></mdb-card-img>
              </div>
              <mdb-card-body>
                <!--Name-->
                <h4 class="fw-bold mt-4">{{ testimony.name }}</h4>
                <hr />
                <!--Position-->
                <h6 class="tags-secondary-color fw-bold my-3">
                  {{ testimony.position }}
                </h6>
                <!--Description-->
                <p class="text-gray" style="white-space: pre-line">
                  {{ testimony.description }}
                </p>
                <!--Socials-->
                <!-- <div class="row justify-content-center">
                  <div
                    *ngFor="
                      let social of testimony.socials;
                      let socialIndex = index
                    "
                  >
                    <a href="{{ social.link }}">
                      <mdb-icon
                        fab
                        icon="{{ social.icon }}"
                        class="p-1 {{ social.style }}"
                      ></mdb-icon>
                    </a>
                  </div>
                </div> -->
              </mdb-card-body>
            </mdb-card>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
