import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TagsInterceptor } from './tags.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { JwtInterceptor } from './jwt.interceptor';

export * from './auth.guard';
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TagsInterceptor, multi: true }
];
