import { AccreditationsPageComponent } from './views/home/accreditations/accreditations-page.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './views/home/home.component';
import { AuthGuard } from './_helpers/auth.guard';
import { FaqComponent } from './views/home/faq/faq.component';
import { AboutComponent } from './views/home/about/about.component';
import { TeamComponent } from './views/home/team/team.component';
import { CommonModule } from '@angular/common';
import { MeetTheCeoComponent } from './views/home/meet-the-ceo/meet-the-ceo.component';
import { TagsTipsComponent } from './views/home/tags-tips/tags-tips.component';
import { DevComponent } from './views/dev/dev.component';

const routes: Routes = [
  {
    path: 'account',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'application',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/application/application.module').then(
        (m) => m.ApplicationModule
      ),
  },
  {
    path: 'batch-renewals',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/batch-renewals/batch-renewals.module').then(
        (m) => m.BatchRenewalsModule
      ),
  },
  {
    path: 'cashier',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/cashier/cashier.module').then((m) => m.CashierModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./views/contact-form/contact-form.module').then(
        (m) => m.ContactFormModule
      ),
  },
  {
    path: 'g27-report',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/g27-report/g27-report.module').then(
        (m) => m.G27ReportModule
      ),
  },
  {
    path: 'inquiry',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/inquiry/inquiry.module').then((m) => m.InquiryModule),
  },
  {
    path: 'inventory',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/inventory/inventory.module').then(
        (m) => m.InventoryModule
      ),
  },
  // {
  //   path: 'login',
  //   loadChildren: () =>
  //     import('./views/login/login.module').then(
  //       (m) => m.LoginModule
  //     ),
  // },
  {
    path: 'pod-report',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/pod-report/pod-report.module').then(
        (m) => m.PodReportModule
      ),
  },
  {
    path: 'refund',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/refund/refund.module').then((m) => m.RefundModule),
  },
  {
    path: 'retail-info',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/retail-info/retail-info.module').then(
        (m) => m.RetailInfoModule
      ),
  },
  {
    path: 'vehicle',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/vehicle/vehicle.module').then((m) => m.VehicleModule),
  },
  {
    path: 'registration',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: 'unrenewed-vehicle-report',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './views/unrenewed-vehicle-report/unrenewed-vehicle-report.module'
      ).then((m) => m.UnrenewedVehicleReportModule),
  },
  { path: 'dev', component: DevComponent },
  { path: 'about', component: AboutComponent },
  { path: 'accreditations', component: AccreditationsPageComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'home', component: HomeComponent },
  { path: 'our-team', component: TeamComponent },
  { path: 'meet-the-ceo', component: MeetTheCeoComponent },
  { path: 'our-tips', component: TagsTipsComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
