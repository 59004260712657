import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  KeyValueDiffers,
  KeyValueDiffer,
  NgZone,
  AfterViewInit,
  HostBinding,
  inject,
  ChangeDetectorRef,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import {
  DOCUMENT,
  Location,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';
import { MdbModalService, MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { LoginComponent } from 'src/app/views/login/login.component';
import { ContactFormComponent } from '../../views/contact-form/contact-form.component';
import { DealerProfile } from '../../_helpers/dealer-profile';
import { UntypedFormBuilder } from '@angular/forms';
import { DealerProfileService } from '../../_services/dealer-profile.service';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { MdbSidenavComponent } from 'mdb-angular-ui-kit/sidenav';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tags-header',
  providers: [Location],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AppHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tagsSidenav', { static: true }) sidenavComp!: MdbSidenavComponent;
  @ViewChild('publicNavbar', { static: false }) publicNavbar: any;
  @ViewChild('vehicleCategories') vehicleCats: any;
  @ViewChild('cardsCategories') cardCats: any;
  @ViewChild('reportsCategories') reportCats: any;

  //@ViewChild('sidenavOld', { static: true }) public sidenavOld: any;

  @HostListener('swiperight', ['$event']) public swipePrev(event: any) {
    this.sidenavComp.show();
  }

  private subs: Subscription[];
  private cd = inject(ChangeDetectorRef);
  loginModal: MdbModalRef<LoginComponent> | null = null;
  contactModal: MdbModalRef<ContactFormComponent> | null = null;
  clicked: boolean;
  dealerProfile: DealerProfile;
  dealerLoggedIn$: Observable<boolean>;

  differ: KeyValueDiffer<any, any>;

  public hideLogin = environment.hideLogin;
  public isVehiclePage = false;
  public collapseVehCats = false;
  public collapseCardCats = false;
  public collapseReportCats = false;

  public mode = 'over';
  public hidden = true;
  public loggedInPadding = false;

  // public changePage(currentLocation: Location) {
  //   // this.loginModal.hide();
  //   console.log('Is this thing called?');
  //   if (this.dealerLoggedIn) {
  //     this.dealerCode;
  //   }
  // }

  constructor(
    private location: Location,
    private router: Router,
    private authService: AuthenticationService,
    private modalService: MdbModalService,
    private toastService: MdbNotificationService,
    private formBuilder: UntypedFormBuilder,
    private ngZone: NgZone,
    private differs: KeyValueDiffers,
    @Inject(PLATFORM_ID) private platformID: any,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.differ = differs.find({}).create();
    this.clicked = this.clicked === undefined ? false : true;
    this.subs = [];
  }

  ngOnInit() {
    this.dealerProfile = new DealerProfile(this.formBuilder, this.authService.currentDealerValue);

    this.dealerLoggedIn$ = this.authService.isLoggedIn$;

    if (isPlatformBrowser(this.platformID)) {
      this.subs.push(
        this.authService.currentDealer.subscribe((data) => {
          // if (data) {
          //   this.loggedInPadding = window.innerWidth >= 1400 ? true : false;
          //   this._hidden = window.innerWidth >= 1400 ? false : true;
          // } else {
          //   this.loggedInPadding = false;
          // }
          this.dealerProfile.createJSONObject(data);
        })
      );
      this.mode = window.innerWidth >= 1400 ? 'side' : 'over';
      this.subs.push(
        this.dealerLoggedIn$.subscribe((data) => {
          this.hidden = window.innerWidth >= 1400 && data ? false : true;
          this.loggedInPadding = true;

          if (!this.hidden) {
            this.showSidenav();
          }
        })
      );
    } else if (isPlatformServer(this.platformID)) {
    }
  }

  ngAfterViewInit(): void {
    // Assigning these category collapse flags in this lifecycle hook
    // gets around ExpressionChangedAfterItWasCheckedError.
    // This error would fire when reloading a page that was within a
    // collapsable category on the sidebar.
    if (this.vehicleCats && this.cardCats && this.reportCats) {
      this.collapseVehCats = !(this.vehicleCats.collapsed as boolean);
      this.collapseCardCats = !(this.cardCats.collapsed as boolean);
      this.collapseReportCats = !(this.reportCats.collapsed as boolean);
    }

    if (isPlatformBrowser(this.platformID)) {
      this.ngZone.runOutsideAngular(() => {
        fromEvent(window, 'resize').subscribe(() => {
          if (
            window.innerWidth < 1400 &&
            this.mode !== 'over' &&
            this.sidenavComp !== undefined
          ) {
            this.ngZone.run(() => {
              this.mode = 'over';
              if (this.authService.loggedIn) {
                this.hideSidenav();
              }
            });
          } else if (
            window.innerWidth >= 1400 &&
            this.mode !== 'side' &&
            this.sidenavComp !== undefined
          ) {
            this.ngZone.run(() => {
              this.mode = 'side';
              if (this.authService.loggedIn) {
                this.showSidenav();
              }
            });
          }
        });
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subs.length > 0) {
      this.subs.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

  get dealerCode(): string | undefined {
    return this.dealerProfile.profile === null
      ? ''
      : this.dealerProfile.profile.code;
  }

  showBatchRenewals(): boolean {
    return this.dealerProfile.profile === null ||
      this.dealerProfile.profile === undefined
      ? false
      : this.dealerProfile.profile.renewals;
  }

  onNavbarClick() {
    if (this.publicNavbar) {
      if (!this.publicNavbar.collapsed) {
        this.publicNavbar.toggle();
      }
    }
  }

  onNavigate() {
    if (this.mode === 'over' && this.sidenavComp !== undefined) {
      this.sidenavComp.hide();
    }
  }

  hideSidenav() {
    if (isPlatformBrowser(this.platformID)) {
      setTimeout(() => {
        this.sidenavComp.hide();
      }, 0);
    }
  }

  showSidenav() {
    if (isPlatformBrowser(this.platformID)) {
      setTimeout(() => {
        this.sidenavComp.show();
      });
    }
  }

  toggleSidenav() {
    if (isPlatformBrowser(this.platformID)) {
      setTimeout(() => {
        this.sidenavComp.toggle();
      });
    }
  }

  openLoginModal() {
    this.onNavbarClick();
    this.loginModal = this.modalService.open(LoginComponent);
  }

  openContactModal() {
    this.onNavbarClick();
    this.contactModal = this.modalService.open(ContactFormComponent, {
      ignoreBackdropClick: true,
      modalClass: 'modal-lg',
    });
  }

  logout() {
    this.authService.logout();
    this.hideSidenav();
    this.router.navigate(['/home']);
    this.showLoggedOut();
  }

  showLoggedOut() {
    const options = {
      opacity: 0.8,
      timeOut: 3000,
      progressBar: false,
      toastClass: '#4bab73',
      positionClass: 'md-toast-top-left',
    };
    // tslint:disable-next-line: quotemark
    //this.toastService.success("You've logged out!", 'Success!', options);
  }

  setClicked(val: boolean): void {
    this.clicked = val;
  }
}
