<footer class="tags-footer text-center text-lg-start">
  <div class="d-flex justify-content-between text-center p-3">
    <p class="my-0 py-0"></p>
    <p class="my-0 py-0">
      © {{ year }} Copyright:
      <a class="text-light" href="https://tagshi.com"
        ><strong>TAGS Hawaii Inc.</strong></a
      >
    </p>
    <p class="my-0 py-0">Version: {{ version }}</p>
  </div>
</footer>
