import {
  NgModule,
  NO_ERRORS_SCHEMA,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestimonialsComponent } from './testimonials/testimonials.component';

import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';

import { CarouselComponent } from './carousel/carousel.component';
import { AccreditationsComponent } from './accreditations/accreditations.component';
import { FloatingMenuComponent } from './floating-menu/floating-menu.component';
import { ControlMessagesComponent } from './control-messages/control-messages.component';
import { HttpMessagesComponent } from './http-messages/http-messages.component';
import { VehicleSearchModalComponent } from './vehicle-search-modal/vehicle-search-modal.component';

import { FormControlStatusDirective } from './form-control-status/form-control-status.directive';

import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NotificationComponent } from './notification/notification.component';
import { PopconfirmComponent } from './popconfirm/popconfirm.component';

@NgModule({
  declarations: [
    TestimonialsComponent,
    CarouselComponent,
    AccreditationsComponent,
    FloatingMenuComponent,
    ControlMessagesComponent,
    HttpMessagesComponent,
    VehicleSearchModalComponent,
    FormControlStatusDirective,
    NotificationComponent,
    PopconfirmComponent,
  ],
  imports: [
    CommonModule,
    MdbCarouselModule,
    MdbCollapseModule,
    MdbFormsModule,
    MdbRippleModule,
    MdbValidationModule,
    ReactiveFormsModule,
  ],
  exports: [
    TestimonialsComponent,
    CarouselComponent,
    AccreditationsComponent,
    FloatingMenuComponent,
    ControlMessagesComponent,
    HttpMessagesComponent,
    FormControlStatusDirective,
    NotificationComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
