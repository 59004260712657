import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DealerProfileService } from '../_services/dealer-profile.service';
export class DealerProfile {
  profile: {
    appendStock: boolean;
    billTo: {
      address1: string;
      address2: string;
      attention: string;
      city: string;
      county: string;
      fax: string;
      name: string;
      phone: string;
      state: string;
      zip: string;
    };
    code: string;
    defaults: {
      air: boolean;
      lienHolderCode: string;
      motivePower: string;
      passenger: boolean;
      transmission: string;
    };
    fein: string;
    renewals: boolean;
    rentalAgency: boolean;
    shipTo: {
      address1: string;
      address2: string;
      attention: string;
      city: string;
      county: string;
      fax: string;
      name: string;
      phone: string;
      state: string;
      zip: string;
    };
  };

  dealerForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private jsonObject: any
  ) {
    this.profile = jsonObject;
    if (this.profile) {
      this.getProfileForm();
    }
  }

  createJSONObject(jsonObject: any) {
    this.profile = jsonObject;
  }

  getProfileForm(): UntypedFormGroup {
    this.dealerForm = this.formBuilder.group({
      rentalAgency: [this.profile.rentalAgency],
      appendStock: [this.profile.appendStock],
      billTo: {
        address1: [this.profile.billTo.address1],
        address2: [this.profile.billTo.address2],
        attention: [this.profile.billTo.attention],
        city: [this.profile.billTo.city],
        county: [this.profile.billTo.county],
        fax: [this.profile.billTo.fax],
        name: [this.profile.billTo.name],
        phone: [this.profile.billTo.phone],
        state: [this.profile.billTo.state],
        zip: [this.profile.billTo.zip],
      },
      code: [this.profile.code],
      fein: [this.profile.fein],
      shipTo: {
        address1: [this.profile.shipTo.address1],
        address2: [this.profile.shipTo.address2],
        attention: [this.profile.shipTo.attention],
        city: [this.profile.shipTo.city],
        county: [this.profile.shipTo.county],
        fax: [this.profile.shipTo.fax],
        name: [this.profile.shipTo.name],
        phone: [this.profile.shipTo.phone],
        state: [this.profile.shipTo.state],
        zip: [this.profile.shipTo.zip],
      },
    });

    return this.dealerForm;
  }
}
