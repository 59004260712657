<!-- Section heading -->
<div>
  <div class="card">
    <div class="card-header card-header-primary text-white text-center">
      <h2 class="h1-responsive text-center font-weight-bold">About TAGS</h2>
    </div>
    <div class="card-body">
      <h1>About page coming soon.</h1>
    </div>
  </div>
</div>
