<div>
  <div class="card">
    <div class="card-header tags-primary-color-bg">
      <h1 class="font-weight-bold text-white text-center">
        Frequently Asked Questions
      </h1>
    </div>
    <div class="card-body">
      <mdb-accordion>
        <mdb-accordion-item>
          <ng-template mdbAccordionItemHeader class="black-text">
            <h5 class="h5-responsive">
              Is TAGS Hawaii the Department of Motor Vehicles (DMV)?
            </h5>
          </ng-template>
          <ng-template mdbAccordionItemBody>
            No, we are not the DMV. Please see contact details below for the
            Department of Motor Vehicles:<br /><br />
            <strong>Honolulu DMV</strong><br />
            Website:
            <a href="https://www8.honolulu.gov/csd/">honolulu.gov</a><br />
            Phone: <a href="tel:808-768-4325">808-768-4325</a><br />
            <hr />
            <strong>Hawaii DMV</strong><br />
            Website:
            <a
              href="https://www.hawaiicounty.gov/departments/finance/vehicle-registration-licensing"
              >hawaiicounty.gov</a
            ><br />
            Phone: <a href="tel:808-961-8351">808-961-8351</a><br />
            <hr />
            <strong>Maui DMV</strong><br />
            Website:
            <a href="https://www.mauicounty.gov/1328/Motor-Vehicle-Licensing"
              >mauicounty.gov</a
            ><br />
            Phone: <a href="tel:808-270-7363">808-270-7363</a><br />
            <hr />
            <strong>Kauai DMV</strong><br />
            Website:
            <a
              href="https://www.kauai.gov/Government/Departments-Agencies/Finance/Drivers-Licensing-and-Motor-Vehicles"
              >kauai.gov</a
            ><br />
            Phone: <a href="tel:808-241-4242">808-241-4242</a>
          </ng-template>
        </mdb-accordion-item>

        <mdb-accordion-item>
          <ng-template mdbAccordionItemHeader class="black-text">
            <h5 class="h5-responsive">Does TAGS Hawaii service the public?</h5>
          </ng-template>
          <ng-template mdbAccordionItemBody>
            We are agents for dealerships, rental car agencies and mainland
            third party title agencies only.
          </ng-template>
        </mdb-accordion-item>

        <mdb-accordion-item>
          <ng-template mdbAccordionItemHeader class="black-text">
            <h5 class="h5-responsive">
              Can TAGS Hawaii process large "one-off" projects for clients?
            </h5>
          </ng-template>
          <ng-template mdbAccordionItemBody>
            Yes we can! Please use the contact form, and we will contact you to
            discuss your requirements.
          </ng-template>
        </mdb-accordion-item>

        <!-- <mdb-accordion-item>
          <mdb-accordion-item-head [customClass]="'black-text'">
            <h5 class="h5-responsive">Can I request a refund?</h5>
          </mdb-accordion-item-head>
          <mdb-accordion-item-body>
            Unfortunately, not. We do not issue full or partial refunds for any
            reason.
          </mdb-accordion-item-body>
        </mdb-accordion-item> -->
      </mdb-accordion>
    </div>
  </div>
</div>
