import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbSidenavModule } from 'mdb-angular-ui-kit/sidenav';
import { MdbNotificationModule } from 'mdb-angular-ui-kit/notification';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  NgModule,
  NO_ERRORS_SCHEMA,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { AppHeaderComponent } from './header/header.component';
import { AppFooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';

@NgModule({
  declarations: [AppHeaderComponent, AppFooterComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MdbAccordionModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbModalModule,
    MdbNotificationModule,
    MdbRippleModule,
    MdbSidenavModule,
    RouterModule,
  ],
  exports: [AppHeaderComponent, AppFooterComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class NavigationModule {}
