<!-- Section heading -->
<div>
  <div class="card">
    <div class="card-header card-header-primary text-white text-center">
      <h2 class="h1-responsive text-center font-weight-bold">Meet the CEO</h2>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-center">
          <img
            src="../../../../assets/staff/stafficons/donna.png"
            style="max-height: 300px"
            class="img-fluid z-depth-1 rounded-circle"
          />
        </div>
        <div class="d-flex justify-content-center my-3">
          <h2 class="text-center text-gray">
            <strong>Meet our CEO, Donna Smallwood</strong>
          </h2>
        </div>
        <div class="d-flex justify-content-center">
          <p class="text-justify text-gray" style="max-width: 50%">
            Aloha!<br /><br />Welcome to Tags Hawaii Inc. My name is Donna
            Smallwood and I take immense pride in being the CEO of this
            wonderful company. We at TAGS have been serving the State of Hawaii
            since 1984 and I have personally owned the company since 2008.<br /><br />I
            love my job, because I love helping other small business owners
            achieve their goals and to watch them succeed. I know what it is
            like to own a small business and, as many of us have, I have worked
            in every department of the company and have worn every hat there is.
            I know what it feels like to succeed, what it feels like to fail,
            and what it feels like on the days in between.<br /><br />I am given
            an opportunity each day to help my clients get closer to their
            goals, both operational and financial -- by leveraging the unique
            and specialized talents of my team in order to provide the best
            title and registration service in the State of Hawaii.<br /><br />In
            a little over one decade, I have built a personal philosophy and a
            business model on the premise that business owners want to work on
            their business, not in their business, and that by outsourcing
            challenging title and registration work it should support the
            business and make it profitable.<br /><br />Further, I personally
            believe that outsourcing to the experts is an investment, not an
            expense. If I am doing my job right, your DMV work is completed fast
            and efficiently, then your registration process becomes more
            streamlined and profitable. Your success becomes our success, and
            everybody wins.<br /><br />The real joy in this business is problem
            solving and seeing the relief in our clients as they achieve their
            title goals. It is in seeing workers working productively and
            happily because of a better process, and in watching our clients'
            businesses grow, not only in volume and revenue, but also in
            operational maturity and the quality of their work.<br /><br />We
            systematically audit and review each client's environment to ensure
            alignment. Delivering over 80 years of title clerk experience on
            hand to help problem solve any challenge that may arise.<br /><br />We
            are committed to constant growth and improvement, as we assume you
            are too. If you are hitting a wall and struggling with the DMV and
            title clerks saying, there must be a better way, I assure you, there
            is, and I would be happy to talk to you about it.<br /><br />Mahalo<br />Donna
            Smallwood
          </p>
        </div>
    </div>
  </div>
</div>
