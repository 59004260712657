import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'tags-dev',
  templateUrl: './dev.component.html',
  styleUrls: ['./dev.component.scss'],
})
export class DevComponent implements OnInit {
  private fb = inject(FormBuilder);
  datePickerForm: FormGroup;

  currentDate: string = 'None Selected';

  ngOnInit(): void {
    this.datePickerForm = this.fb.group({
      datePicker: ['', Validators.required],
    });
  }

  onDateChanged(event: Date) {
    this.currentDate = event.toString();
  }
}
