import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';

import { AuthenticationService } from '../_services';
import { type } from 'os';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  displayMessage: string;
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // tap((event) => {
      //   if (event instanceof HttpErrorResponse) {
      //     console.error('error-interceptor.tap: HttpErrorResponse: ', event);
      //   } else if (event instanceof HttpResponse) {
      //     this.displayMessage = '';

      //     if (event.status === 200) {
      //       this.displayMessage = 'Success';
      //     } else if (event.status >= 400) {
      //       this.displayMessage = 'Error: ' + event.status + ' ' + event.body;
      //     }
      //   }
      // }),
      catchError((err) => {
        this.displayMessage = '';
        let errorObject: any = {};

        // If-else block to account for errors returned from
        // reports endpoints that expect arraybuffer responses.
        if (err.error instanceof ArrayBuffer) {
          // Decode the arraybuffer into a JSON string
          const decodedString = String.fromCharCode.apply(
            null,
            new Uint8Array(err.error)
          );
          // Parse the JSON string into an object
          errorObject = JSON.parse(decodedString);
        } else {
          errorObject = err.error;
        }

        switch (err.status) {
          case 200:
            this.displayMessage = errorObject.detail;
            break;
          case 400:
            this.displayMessage = errorObject.detail;
            break;
          case 401:
            this.displayMessage = 'Invalid login credentials.';
            break;
          case 404:
            this.displayMessage =
              "Sorry, we couldn't find what you're looking for.";
            break;
          case 500:
            this.displayMessage =
              "There's something wrong with the server. Please contact support and try again later.";
            break;
          default:
            this.displayMessage = err.status + ' ' + errorObject.detail;
        }

        const error =
          this.displayMessage || errorObject.detail || err.statusText;
        return throwError(() => {
          return error;
        });
      })
    );
  }
}
