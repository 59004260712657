import { Component } from '@angular/core';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';

@Component({
  selector: 'tags-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  header: string | null = null;
  text: string | null = null;
  severity: string | null = null;

  constructor(
    public notificationRef: MdbNotificationRef<NotificationComponent>
  ) {}
}
