import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Component,
  OnInit,
  HostListener,
  Inject,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MdbCarouselComponent } from 'mdb-angular-ui-kit/carousel';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ContactFormComponent } from 'src/app/views/contact-form/contact-form.component';

@Component({
  selector: 'tags-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  contactModal: MdbModalRef<ContactFormComponent> | null = null;

  constructor(
    private modalService: MdbModalService,
    @Inject(PLATFORM_ID) private platformID: any
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformID)) {
    } else if (isPlatformServer(this.platformID)) {
    }
  }

  isBrowser() {
    return isPlatformBrowser(this.platformID);
  }

  isPortrait() {
    if (this.isBrowser()) {
      return window.innerWidth >= 992;
    } else {
      return false;
    }
  }

  openContactModal() {
    this.contactModal = this.modalService.open(ContactFormComponent, {
      ignoreBackdropClick: true,
      modalClass: 'modal-lg',
    });
  }
}
