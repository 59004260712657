<div
  class="toast fade mx-auto"
  [ngClass]="{'toast-success': severity === 'success', 'toast-danger': severity === 'error', 'toast-info': severity === null}"
  id="tags-notification"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
>
  <div
    class="toast-header"
    [ngClass]="{'toast-success': severity === 'success', 'toast-danger': severity === 'error', 'toast-info': severity === null}"
  >
    <strong class="me-auto">{{ header }}</strong>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="notificationRef.close()"
    ></button>
  </div>
  <div class="toast-body">{{ text }}</div>
</div>
